const mask = () => {

  // Маска для номера телефона

  function maskPhone(selector, masked = '+7 (___) ___-__-__') {
    var err = 0;
    var errFilled = 0;
    const elems = document.querySelectorAll(selector);


    // START -- Работа с form
    const Forms = document.querySelectorAll("form");

    if(Forms) {
      for (let index = 0; index < Forms.length; index++) {
        const form = Forms[index];
        let submitBtn = form.querySelector("[type=submit]");

        if(form.querySelector(selector)) {
          form.addEventListener("submit", (e) => {
            if(err > 0) {
              console.log(form, "неверный номер");
              e.preventDefault();
            }
            if (errFilled > 0) {
              console.log(form, "неполный номер");
              e.preventDefault();
            }
          })

        }
      }
    }
    // END -- Работа с form

    if(elems) {

      let check8 = false;

      function mask(event) {

        // Устанавливаем курсор в конец строки
        this.selectionStart = this.selectionEnd = this.value.length;

        const keyCode = event.keyCode;
        const template = masked,
          def = template.replace(/\D/g, ""),
          val = this.value.replace(/\D/g, "");
        let i = 0,
          newValue = template.replace(/[_\d]/g, function (a) {
            return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
          });
        i = newValue.indexOf("_");
        if (i !== -1) {
          newValue = newValue.slice(0, i);
        }
        let reg = template.substr(0, this.value.length).replace(/_+/g,
          function (a) {
            return "\\d{1," + a.length + "}";
          }).replace(/[+()]/g, "\\$&");
        reg = new RegExp("^" + reg + "$");
        if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
          this.value = newValue;
        }

        if ((event.type === "blur" || event.type === "mouseout") && this.value.length < 5) {
          this.value = "";
        }


        // CUSTOM MASK
        let currentValue = this.value;
        let inputNow = this;
        let secondNum = currentValue.substr(4, 1);
        let firstNum1 = currentValue.substr(0, 2);

        let wrap = inputNow.parentElement;
        wrap.classList.add("input-wrap");

        if (firstNum1 === "+7") {
          check8 = false;
        }

        if (firstNum1 !== "+7") {
          if (firstNum1 !== "+8") {
            this.value = "+7 (";
            check8 = true;
            currentValue = this.value;
          }

        }

        if(!check8) {
          if(secondNum === "8") {
            this.value = "8 (";
            check8 = true;
            currentValue = this.value;
          }
        }

        if(inputNow.selectionStart <= 4) {

          setTimeout(() => {
            inputNow.selectionStart = inputNow.selectionEnd = 4;
          });
        }

        inputNow.addEventListener("click", () => {
          if(inputNow.selectionStart <= 4) {

            setTimeout(() => {
              inputNow.selectionStart = inputNow.selectionEnd = 4;
            });
          }
        });

        function checkErrorInput() {
          if (err > 0) {
              inputNow.classList.add("_error");
              wrap.classList.add("active");

          } else {
            if(inputNow.classList.contains("_error")) {
              inputNow.classList.remove("_error");
              wrap.classList.remove("active");
            }
          }
        }

        let checkSecondNumFunc = (input) => {
          input.addEventListener("input", () => {
            let inputValue = input.value;
            checkErrorInput();

            if (inputValue.substr(0, 2) === "+7") {
              if((inputValue.substr(4, 1) === "1") || (inputValue.substr(4, 1) === "2") || (inputValue.substr(4, 1) === "7")){
                err = 1;
                checkErrorInput()
              } else {
                err = 0;
              }
            } else {
              if((inputValue.substr(3, 1) === "1") || (inputValue.substr(3, 1) === "2") || (inputValue.substr(3, 1) === "7")){
                err = 1;
                checkErrorInput()
              } else {
                err = 0;
              }
            }

          });
        }

        checkSecondNumFunc(inputNow);

        // Валидация +7

        let firstNum = currentValue.substr(0, 2);

        var backspaceFunc = (event) => {
          var key = event.keyCode || event.charCode;

          if( key == 8 || key == 46 || key === "Backspace" ) {

            let lastChar = currentValue.substr(currentValue.length - 1);

            if(lastChar === "-") {
              const withoutLastChunk = currentValue.slice(0, currentValue.lastIndexOf("-"));
              inputNow.value = withoutLastChunk;
            }

            if(lastChar === " ") {
              const withoutLastChunk = currentValue.slice(0, -2);
              inputNow.value = withoutLastChunk;
            }

          }
        }

        this.onkeypress = function(event) {
          backspaceFunc(event);
        };

        if(firstNum === "+8") {

          let newValue = this.value.replace('+8','8');
          this.value = newValue;

          this.onkeydown = function(event) {
            backspaceFunc(event);
          };
        }

      }

      // Функция проверки на заполненность номера телефона
      function checkFilled() {
        let stroke = this.value;
        let inputNow = this;
        let wrap = inputNow.parentElement;
        let submitBtn = inputNow.closest("form").querySelector("[type=submit]");
        let submitBtn2 = document.querySelector(".unicheckout-sticky__confirm-btn");

        wrap.classList.add("input-wrap");

        if(stroke.indexOf("+") > -1) {
          if(stroke.length < 18) {
            errFilled = 1;
            wrap.classList.add("no-filled");
            if(submitBtn) {
              //submitBtn.disabled = true;
            }
            if(submitBtn2) {
              //submitBtn2.disabled = true;
            }
            inputNow.classList.add("_error-no-filled");
          } else {
            errFilled = 0;
            wrap.classList.remove("no-filled");
            if(submitBtn) {
              //submitBtn.disabled = false;
            }
            if(submitBtn2) {
              //submitBtn2.disabled = false;
            }
            inputNow.classList.remove("_error-no-filled");
          }
        } else {
          if(stroke.length < 17) {
            errFilled = 1;
            wrap.classList.add("no-filled");
            if(submitBtn) {
              //submitBtn.disabled = true;
            }
            if(submitBtn2) {
              //submitBtn2.disabled = true;
            }
            inputNow.classList.add("_error-no-filled");
          } else {
            errFilled = 0;
            wrap.classList.remove("no-filled");
            if(submitBtn) {
              //submitBtn.disabled = false;
            }
            if(submitBtn2) {
              //submitBtn2.disabled = false;
            }
            inputNow.classList.remove("_error-no-filled");
          }
        }
      }

      var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13

      for (const elem of elems) {
        elem.addEventListener("input", mask);
        elem.addEventListener("focus", mask);
        elem.addEventListener(iOS ? 'mouseout' : 'blur', mask);
        elem.addEventListener(iOS ? 'mouseout' : 'blur', checkFilled);
      }

    }

  }

  maskPhone(".mask-phone-js", "+7 (___) ___-__-__");

}

export default mask;
